// Set site info
// var siteinfo = document.getElementsByName("siteinfo")[0];
// var site_id = siteinfo.getAttribute("data-siteid");
// var lang = siteinfo.getAttribute("data-sitelang");



$(document).ready(function ()
{

	$( ".navbar-toggler" ).click(function() {
		if($( ".navbar-toggler" ).hasClass('show')) {
			$( ".navbar-toggler" ).removeClass('show')
			//$( ".navbar-toggler-icon" ).css("background-image", "url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e)");

		}
		else {
			$( ".navbar-toggler" ).addClass('show')
			//$( ".navbar-toggler-icon" ).css("background-image", "url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3d='M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z'/%3e%3c/svg%3e)");

		}
		$('.nav-overlay').toggleClass("d-none");
		if ($(".header").hasClass("position-fixed")) {
			$('.header').removeClass('position-fixed')
			$('.header').removeClass('w-100')
		}else{
			$('.header').addClass('position-fixed')
			$('.header').addClass('w-100')
		}
	});


	/*
	 *	PRICE TOGGLER
	 */

	$('.price-star').hide();

	$('#payment-period').on('change', function(e) {
		var selected = $('#payment-period option:selected').attr('value');

		$('[data-price-' + selected + ']').each(function(index, div) {
			$(this).html($(this).attr('data-price-'+selected));
		});
		
		if (selected == 'year') {
			$('.prices-info').show();
			$('.price-star').hide();
		} else {
			$('.prices-info').hide();
			$('.price-star').show();
		}
	});


	/*
	 *	RICH-CONTENT
	 */

	// Lightbox
	$('.lightbox').fancybox({
		buttons: ['close']
	});
	$('.lightbox-media').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true,
		helpers : {
			media : {}
		}
	});

	// Youtube/Vimeo thumbs
	$(".rc-video__thumb").not('.lightbox-media').click( startVideo );
	$(".rc-video__overlay").not('.lightbox-media').click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('data-src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			if( src.indexOf('?') > -1 )
			{
				iframe.attr('src', src + '&autoplay=1');
			}
			else
			{
				iframe.attr('src', src + '?autoplay=1');
			}
		}

		$('.rc-video__overlay', block.parent()).fadeOut(150);
		$('.rc-video__thumb', block.parent()).fadeOut(150);
	}

	// Contentblocks slider
	$('.rc-article-slider').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	// Quote slider
	$('.rc-quote-slider__slick').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	// File upload
	bsCustomFileInput.init()
});

$("#quote-slider").slick({
	prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
	nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	arrows: true,
	dots: false,
	infinite: false,
	speed: 1200,
	slidesToShow: 2,
	slidesToScroll: 1,
	variableWidth: false,
	responsive: [
		{
			breakpoint: 1380,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				variableWidth: false,
			}
		},
		{
			breakpoint: 758,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				variableWidth: false,
				dots: true,
				arrows: false,
			}
		},
	]
});

$("#news-slider").slick({
	prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
	nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	arrows: true,
	dots: false,
	infinite: false,
	speed: 1200,
	slidesToShow: 1,
	slidesToScroll: 1,
	variableWidth: false,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				variableWidth: false,
				dots: true,
				arrows: false,
			}
		},
		{
			breakpoint: 758,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				variableWidth: false,
				dots: true,
				arrows: false,
			}
		},
	]
});
$(window).on('load', function ()
{
	// Image slider (options in data-slick)
	$('.rc-image-slider__slick').slick();
});



// CAPTCHA
var captcha_validated = false;

function onSubmitreCAPTCHA(response)
{
	captcha_validated = true;
	$('.rc-form form').submit();
}

function validateForm(event)
{
	event.preventDefault();
	var form = this;

	var validator = $(form).data('bs.validator');
	validator.validate();

	if (captcha_validated && !validator.hasErrors()) {
		form.submit();
	} else if(!captcha_validated) {
		grecaptcha.reset();
		grecaptcha.execute();
	}
}

$(window).on('load', function() {

	/*
    *  POPUP
    */

	$('#popup').modal('show');
})
